<template>
  <div class="container">
    <h1>Good Oil Products</h1>
    <div v-if="successMessage" class="alert alert-success">
      {{ successMessage }}
    </div>

    <!-- Tab controls -->
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link" :class="{ active: activeTab === 'without' }" @click="activeTab = 'without'">Without Affiliate Links</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{ active: activeTab === 'with' }" @click="activeTab = 'with'">With Affiliate Links</a>
      </li>
    </ul>

    <!-- Tab content -->
    <table class="table">
      <thead>
        <tr>
          <th>Img</th>
          <th>Title</th>
          <th>Amz</th>
          <th>Upc</th>
          <th>Affiliate Link</th>
          <th>Category</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="food in filteredFoods" :key="food.id">
          <td><img :src="food.image_url" style="width:50px;" /></td>
          <td>{{ food.name }}</td>
          <td>
            <a :href="generateAmazonSearchUrl(food.name)" target="_blank" class="btn btn-info">AMZ</a>
          </td>
          <td>{{ food.upc }}</td>
          <td>{{ food.affiliate_url || 'Not set' }}</td>
          <td>
            <select v-model="food.category_id" @change="updateCategory(food)">
              <option v-for="category in categories" :key="category.id" :value="category.id">
                {{ category.name }}
              </option>
            </select>
          </td>
          <td>
            <form @submit.prevent="updateAffiliate(food)">
              <input
                type="url"
                v-model="food.newAffiliateUrl"
                placeholder="Enter new affiliate link"
                required
              />
              <button type="submit" class="btn btn-primary">Update</button>
            </form>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    foods: {
      type: Array,
      default: () => []
    },
    categories: Array,
  },
  data() {
    return {
      successMessage: '',
      activeTab: 'without'  // Default tab
    };
  },
  computed: {
    filteredFoods() {
      if (this.foods && this.foods.length) {
        return this.foods.filter(food => this.activeTab === 'without' ? !food.affiliate_url : food.affiliate_url);
      }
      return [];
    }
  },
  methods: {
    fetchFoods() {
      axios.get('/admin/products/good-oil-products').then((response) => {
        this.foods = response.data.map((food) => ({
          ...food,
          newAffiliateUrl: food.affiliate_url,
        }));
      });
    },
    updateCategory(food) {
      axios
        .post(`/admin/products/update-category/${food.id}`, {
          category_id: food.category_id,
        })
        .then((response) => {
          this.successMessage = 'Category updated successfully!';
        })
        .catch((error) => {
          console.error('Failed to update category:', error);
        });
    },
    generateAmazonSearchUrl(foodName) {
      const baseAmazonUrl = "https://www.amazon.com/s?k=";
      return baseAmazonUrl + encodeURIComponent(foodName);
    },
    updateAffiliate(food) {
      axios
        .post(`/admin/products/update-affiliate-link/${food.id}`, {
          affiliateUrl: food.newAffiliateUrl,
        })
        .then((response) => {
          this.successMessage = 'Affiliate link updated successfully!';
          food.affiliate_url = food.newAffiliateUrl;
        })
        .catch((error) => {
          console.error('Failed to update affiliate link:', error);
        });
    },
  },
  created() {
    this.fetchFoods();
  },
};
</script>

<style>
/* Add any custom styles here */
</style>