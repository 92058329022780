<template>
  <div class="container mt-4">
    <h1>Products with Unknown Ingredients</h1>

    <div v-if="loading" class="alert alert-info">Loading products...</div>
    <div v-if="error" class="alert alert-danger">{{ error }}</div>

    <table class="table table-bordered" v-if="!loading && products.length > 0">
      <thead>
        <tr>
          <th>UPC</th>
          <th>Name</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="product in products" :key="product.id" v-if="product">
          <td>{{ product.upc }}</td>
          <td>{{ product.name }}</td>
          <td>
            <button class="btn btn-primary btn-sm" @click="openEditModal(product)">Edit Ingredients</button>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="!loading && products.length === 0" class="alert alert-warning">No products found with unknown ingredients.</div>

    <!-- Bootstrap Modal -->
    <div v-if="selectedProduct" class="modal fade" id="editModal" tabindex="-1" role="dialog" aria-labelledby="editModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel">Edit Ingredients for {{ selectedProduct.name }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="updateIngredients">
              <div class="form-group">
                <label for="ingredients">Ingredients</label>
                <textarea v-model="newIngredients" class="form-control" id="ingredients" rows="4" required></textarea>
              </div>
              <button type="submit" class="btn btn-success">Save Changes</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [],
      selectedProduct: null,
      newIngredients: '',
      loading: true,
      error: ''
    };
  },
  mounted() {
    this.fetchProducts();
  },
  methods: {
    async fetchProducts() {
      try {
        const response = await fetch('/admin/unknown/fetch');
        const data = await response.json();
        
        // Ensure valid data
        if (Array.isArray(data)) {
          this.products = data;
        } else {
          this.error = "Invalid data received from the server.";
        }
      } catch (error) {
        this.error = "Failed to load products.";
      } finally {
        this.loading = false;
      }
    },
    openEditModal(product) {
      this.selectedProduct = product;
      this.newIngredients = ''; // Reset the textarea
      $('#editModal').modal('show'); // Use jQuery to show the modal
    },
    async updateIngredients() {
      try {
        const response = await fetch('/admin/ingredients', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          },
          body: JSON.stringify({
            food_id: this.selectedProduct.id,
            ingredients: this.newIngredients
          })
        });

        const data = await response.json();

        if (data.success) {
          alert('Ingredients updated successfully!');

          // Remove the product from the list after updating
          this.products = this.products.filter(product => product.id !== this.selectedProduct.id);

          $('#editModal').modal('hide');
        } else {
          this.error = "Failed to update ingredients.";
        }
      } catch (error) {
        console.error("Error updating ingredients:", error);
        this.error = "An error occurred while updating the ingredients.";
      }
    }
  }
};
</script>